import { motion } from "framer-motion";

export default function HomePage() {

  return (
    <div className="home-centered">
      <motion.div initial={{opacity: 0, y: -50}} animate={{opacity: 1, y: 0}} transition={{duration: .5, ease: 'easeInOut'}} className="title-centered">
        <div className="header">
          <img src="/static/images/icons/code-solid.svg" alt="code" />
          <h1>
            <span>IlGabbo</span> server
          </h1>
        </div>
        <motion.div className="main" initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0}} transition={{delay: .4, ease: 'easeInOut'}}>
          <p>Enthusiastic about coding and self-hosting</p>
        </motion.div>
        <motion.div className="footer" initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0}} transition={{delay: .5, ease: 'easeInOut'}}>
          <a className="button" href="https://github.com/IlGabbo">
            <div className="button-content">
              <img src="/static/images/icons/github-mark.svg" alt="github" />
              GitHub
            </div>
          </a>
          <a className="button secondary" href="https://discordapp.com/users/424934230351544330">
            <div className="button-content">
              <img src="/static/images/icons/discord.svg" alt="discord" />
              Discord
            </div>
          </a>
        </motion.div>
      </motion.div>
    </div>
  )
}

